.order-history {
    background: white;
    flex-wrap: wrap;
}
.order-history-card {
    width:31.5%;
    min-width:310px;
}
.order-history-card:hover {
    cursor: pointer;
    background: rgba(204, 204, 204, 0.16);
}
.order-history-datepicker {
    width:120px;
    text-align:center;
}
