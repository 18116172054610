.google-places-autocomplete__input {
    width: 100%;
    border: 1px solid #ccc !important;
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
    box-shadow: none;
}
.required-asterisk {
    color:red;
    font-weight:bold;
}

/* google-places-autocomplete__input */
.orderForm {
    font-family: 'Montserrat', sans-serif;
}
.orderForm input[type=text],
.orderForm select,
.orderForm textarea {
    background:ghostwhite;
    border:2px solid #b5c7ff;
}

.orderForm input[type=text]:focus,
.orderForm textarea:focus{
    border:2px solid #2a5eff;
    outline:none;
    box-shadow:none;
}
.deliveryDataBlock {
    background:#fafafa;
}
.submitOrderFormButton {
    width:100%;
    max-width:763px;
    height:69px;
    padding:0;
    background: #2A5EFF 0% 0% no-repeat padding-box;
    border-radius:35px;
    font-weight:bold;
    font-size:16pt;
    color:white;
    font-family: 'Montserrat', sans-serif;
    letter-spacing:0;
}
.submitOrderFormButton:disabled {
    opacity: 0.3;
}
.orderFormWrapper {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #00000029;
    border-radius: 5px;
    border:none;
    opacity: 1;
}
