.order-list-wrapper {background: #FFFFFF;box-shadow: 0px 3px 20px #00000029;border:none;border-radius: 5px;width:100%;}
.order-reference {font-weight:bold;font-size:10pt;color: #2A5EFF;}
.order-card {width: 21rem;font-family: 'Montserrat', sans-serif;}
.order-card .driver-info-badge:hover {cursor: pointer;}
.order-card .mybadge {max-width: 200px;width: 100%; height: 21px;color: white;font-size: 8pt;font-weight: 600;line-height: 14px;text-align: center;letter-spacing: 0px;padding: 5px 20px;margin: 7px auto;display: block;border-radius: 100px;}
.order-card .mybadge.delivering {background: #3FC468;}
.order-card .mybadge.in-pickup {background: #3FC468;}
.order-card .mybadge.driving-to-pickup {background: #5398E8;}
.order-card .mybadge.cancelled {background:mediumvioletred;}
.order-card .mybadge.requesting {background: #9D9D9D;}
.order-card.bit-faded {opacity:0.3;}
@media only screen and (max-width: 760px)  {
    .order-card {width:100% !important;}
}
@media only screen and (max-width: 1090px)  {
    .order-card {width:45%;}
}
