.login-form-wrapper {width:100%;margin: 60px auto 0 auto !important;max-width:424px;min-width:260px;background:white;border-radius:10px;box-shadow: 0px 3px 20px #00000083;}
.login-form-button {font-family: 'Muli', sans-serif !important;background:#2A5EFF !important;border-radius:35px !important;font-weight:bold !important;max-width:285px !important;width:100%;display:block !important;height:61px;font-size:1.25em !important;}
.order-login-container {background: url("/login/background.jpg");font-family: 'Muli';background-repeat: no-repeat;background-size: cover;background-position: center top;max-width:1518px !important;max-height:1050px !important;height:1050px !important;border-radius:50px;width:100%;}
.login-form-heading-1 {color:white;font-size:36pt;margin-top:20px;font-family: 'Baloo Paaji 2', cursive;}
.login-form-heading-2 {font-size:18pt;color:white;}
.login-page-input-wrapper {position:relative;height:53px;}
.login-page-input-wrapper .login-form-text-input {background: #F4F6FF !important;border: 1px solid #B5C7FF !important;border-radius: 5px;height:53px !important;line-height:53px !important;padding: 3px 10px 3px 40px !important;position:absolute;left:0;top:0;}
.login-page-input-wrapper .input-icon {position:absolute;left:10px;top:15px;z-index:1;}
.login-form-upper-title {color:black;font-size:1.45em;font-weight:bold;letter-spacing:0px;font-family: 'Muli', sans-serif;}
.login-page-footer-contacts {height:67px;font-family: 'Muli', sans-serif !important;font-weight:bold;font-size:1em;}
.login-page-footer-contacts a {color: black;}
.login-page-footer-contacts a.underline {border-bottom:1px solid black;}
.login-page-footer-contacts a.underline:hover {text-decoration:none;}
.login-page-footer-contacts > .col {line-height:67px;}
.login-page-align-icon-upper {margin-top:-2px;}
@media only screen and (max-width: 1600px)  {
    .order-login-container {border-radius:0;}
}
